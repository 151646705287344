import { useState, useEffect, lazy, Suspense, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import SmoothScroll from 'smooth-scroll';
import client from './contentfulClient';
import JsonData from './data/data.json';
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Import critical components normally
import { Header } from './components/Header';
import { HeroSection } from './components/HeroSection';
import PerformanceOverlay from './components/PerformanceOverlay';

// Lazy load non-critical components with error boundaries
const BlogPostDetail = lazy(() => import('./components/BlogPostDetail')
  .then(module => ({ 
    default: module.default || module.BlogPostDetail 
  }))
  .catch(error => {
    console.error('Error loading BlogPostDetail:', error);
    return { 
      default: () => <div>Error loading blog post</div> 
    };
  })
);

const BlogSection = lazy(() => import('./components/BlogSection')
  .then(module => ({ 
    default: module.default || module.BlogSection 
  }))
  .catch(error => {
    console.error('Error loading BlogSection:', error);
    return { 
      default: () => <div>Error loading blog</div> 
    };
  })
);
// Lazy load non-critical components
const ContactFormSection = lazy(() => import('./components/ContactFormSection').then(module => ({ default: module.ContactFormSection })));
const AboutSection = lazy(() => import('./components/AboutSection').then(module => ({ default: module.AboutSection })));
const ServicesSection = lazy(() => import('./components/ServicesSection').then(module => ({ default: module.ServicesSection })));
const HowItWorksSection = lazy(() => import('./components/HowItWorksSection').then(module => ({ default: module.HowItWorksSection })));
const ThankYouSection = lazy(() => import('./components/ThankYouSection').then(module => ({ default: module.ThankYouSection })));
const TestimonialsSection = lazy(() => import('./components/TestimonialsSection').then(module => ({ default: module.TestimonialsSection })));
const Footer = lazy(() => import('./components/Footer').then(module => ({ default: module.Footer })));
const ScrollToTop = lazy(() => import('./components/ScrollToTop').then(module => ({ default: module.ScrollToTop })));
const PropertyListings = lazy(() => import('./components/PropertyListings')
  .then(module => ({ 
    default: module.default || module.PropertyListings 
  }))
  .catch(error => {
    console.error('Error loading PropertyListings:', error);
    return { 
      default: () => <div>Error loading properties</div> 
    };
  })
);
const PropertyDetails = lazy(() => import('./components/PropertyDetails')
  .then(module => ({ 
    default: module.default || module.PropertyDetails 
  }))
  .catch(error => {
    console.error('Error loading PropertyDetails:', error);
    return { 
      default: () => <div>Error loading property details</div> 
    };
  })
);

// Optimize smooth scroll
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 800,
  speedAsDuration: true,
});

// Cache implementation
const cache = new Map();
const CACHE_DURATION = 1000 * 60 * 5; // 5 minutes

const LoadingSpinner = () => (
  <div className="loading-skeleton" aria-label="Loading content..." />
);

// Data processing functions
const processHeroData = (response) => {
  if (!response.items.length) return null;
  const heroData = response.items[0].fields;
  if (heroData.backgroundImage) {
    heroData.backgroundImageUrl = `https:${heroData.backgroundImage.fields.file.url}?fm=webp&q=80`;
  }
  return heroData;
};

const processServicesData = (response) => {
  if (!response.items.length) return null;
  const servicesSectionEntry = response.items[0].fields;
  const services = servicesSectionEntry.services.map((service) => ({
    icon: service.fields.icon,
    name: service.fields.name,
    text: service.fields.text,
    buttonText: service.fields.buttonText,
    order: service.fields.order || 0,
  }));
  services.sort((a, b) => a.order - b.order);
  return {
    title: servicesSectionEntry.title,
    paragraph: servicesSectionEntry.paragraph,
    services: services,
  };
};

const processAboutData = (response) => {
  if (!response.items.length) return null;
  const aboutData = response.items[0].fields;
  return {
    title: aboutData.title || 'Our Focus',
    paragraph: aboutData.paragraph,
    items: aboutData.items.map((item) => ({
      title: item.fields.itemHeader,
      text: item.fields.itemParagraph,
    })),
  };
};

const processHowItWorksData = (response) => {
  if (!response.items.length) return null;
  const howItWorksData = response.items[0].fields;
  return {
    title: howItWorksData.title,
    steps: howItWorksData.steps.map((step) => ({
      icon: step.fields.icon,
      title: step.fields.title,
      text: step.fields.text,
    })),
  };
};

const processTestimonialsData = (response) => {
  console.log('Processing testimonials data:', response);
  
  if (!response.items.length) {
    console.log('No testimonials items found in response');
    return null;
  }
  
  const testimonialsData = response.items[0].fields;
  console.log('Testimonials data fields:', testimonialsData);
  
  // Return data with TestimonialsSection wrapper
  return {
    TestimonialsSection: {
      title: "What Our Clients Say",
      testimonials: testimonialsData.testimonials?.map(testimonial => {
        console.log('Processing individual testimonial:', testimonial);
        return {
          name: testimonial.fields.name,
          text: testimonial.fields.text,
          image: testimonial.fields.image,
          rating: testimonial.fields.rating || 5,
          source: testimonial.fields.source || 'Google',
          order: testimonial.fields.order || 0
        };
      }) || []
    }
  };
};

const processBlogPostsData = (response) => {
  if (!response.items.length) return null;
  return response.items.map((post) => ({
    title: post.fields.title,
    slug: post.fields.slug,
    description: post.fields.description,
    imgSrc: post.fields.image ? `https:${post.fields.image.fields.file.url}?fm=webp&q=80` : '',
    author: post.fields.author,
    date: post.fields.date,
    content: post.fields.content,
    shareOptions: post.fields.shareOptions ? post.fields.shareOptions.map((option) => option.fields) : [],
  }));
};

const processThankYouData = (response) => {
  if (!response.items.length) return null;
  return response.items.map((item) => ({
    icon: item.fields.icon,
    name: item.fields.name,
    text: item.fields.text,
    buttonText: item.fields.buttonText,
    link: item.fields.url,
  }));
};

const processBlogSectionData = (response) => {
  if (!response.items.length) return null;
  const blogSectionData = response.items[0].fields;
  return {
    headerImageUrl: blogSectionData.headerImage ? `https:${blogSectionData.headerImage.fields.file.url}?fm=webp&q=80` : null,
    title: blogSectionData.title || 'Our Blog',
    subtitle: blogSectionData.subtitle || 'Stay updated with our expert insights and latest trends.',
  };
};

const AppContent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [landingPageData, setLandingPageData] = useState({});
  const [showContact, setShowContact] = useState(false);
  const [overlayStatus, setOverlayStatus] = useState('none');
  const [currentService, setCurrentService] = useState(null);
  const [showThankyou, setShowThankyou] = useState(false);
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check cache first
        const cachedData = cache.get('landingPageData');
        if (cachedData && Date.now() - cachedData.timestamp < CACHE_DURATION) {
          setLandingPageData(cachedData.data);
          return;
        }

        // Fetch critical data first
        const [heroResponse, servicesResponse] = await Promise.all([
          client.getEntries({ content_type: 'heroSection' }),
          client.getEntries({ content_type: 'servicesSection', include: 2 })
        ]);

        const initialData = {
          HeroSection: processHeroData(heroResponse),
          ServicesSection: processServicesData(servicesResponse)
        };

        setLandingPageData(initialData);

        // Fetch remaining data after initial render
        setTimeout(async () => {
          const [
            aboutResponse,
            howItWorksResponse,
            testimonialsResponse,
            blogPostsResponse,
            thankYouResponse,
            blogSectionResponse
          ] = await Promise.all([
            client.getEntries({ content_type: 'aboutSection', include: 2 }),
            client.getEntries({ content_type: 'howItWorksSection', include: 2 }),
            client.getEntries({ content_type: 'testimonialsSection', include: 2 }),
            client.getEntries({ content_type: 'blogPost', order: '-fields.date' }),
            client.getEntries({ content_type: 'thankYouSection', include: 2 }),
            client.getEntries({ content_type: 'blogSection' })
          ]);
          
          console.log('Raw testimonials response:', testimonialsResponse);
          const fullData = {
            ...initialData,
            AboutSection: processAboutData(aboutResponse),
            HowItWorksSection: processHowItWorksData(howItWorksResponse),
            TestimonialsSection: processTestimonialsData(testimonialsResponse),
            BlogPosts: processBlogPostsData(blogPostsResponse),
            ThankYouSection: processThankYouData(thankYouResponse),
            BlogSection: processBlogSectionData(blogSectionResponse)
          };

          setLandingPageData(fullData);
          cache.set('landingPageData', {
            data: fullData,
            timestamp: Date.now()
          });
        }, 0);

      } catch (error) {
        console.error('Error fetching data:', error);
        setLandingPageData(JsonData);
      }
    };

    fetchData();
  }, []);

  // Optimize animation timings
  const animateOverlay = useCallback((callback) => {
    setOverlayStatus('entering');
    setTimeout(() => {
      setOverlayStatus('entered');
      setTimeout(() => {
        callback?.();
        setOverlayStatus('exiting');
        requestAnimationFrame(() => {
          setOverlayStatus('none');
        });
      }, 300);
    }, 300);
  }, []);

  const handleGetStartedClick = useCallback(() => {
    setCurrentService('Get Started');
    animateOverlay(() => setShowContact(true));
  }, [animateOverlay]);

  const handleServicesClick = useCallback((serviceIdentifier) => {
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        animateOverlay(() => setShowContact(true));
      }, 100);
    } else {
      animateOverlay(() => setShowContact(true));
    }
    setCurrentService(serviceIdentifier);
    scroll.animateScroll(0);
  }, [location.pathname, navigate, animateOverlay]);

  const handleFormSubmit = useCallback(async (formData) => {
    try {
      setShowHeader(false);
      animateOverlay(() => {
        setShowThankyou(true);
        setShowContact(false);
      });
    } catch (error) {
      console.error('Error in handleFormSubmit:', error);
    }
  }, [animateOverlay]);

  const handleFooterClick = useCallback((section) => {
    if (location.pathname !== '/') {
      animateOverlay(() => {
        navigate('/');
        window.scrollTo(0, 0);
      });
    } else {
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.pathname, navigate, animateOverlay]);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ScrollToTop />
      {process.env.NODE_ENV === 'development' && <PerformanceOverlay />}
      <Header
        onBuyClick={handleServicesClick}
        onSellClick={() => handleServicesClick('Sell Your Home Immediately')}
      />
      <Routes>
        <Route 
          path="/blog" 
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <Helmet>
                <link rel="canonical" href="https://www.vidcor.com/blog" />
              </Helmet>
              <BlogSection data={landingPageData} />
            </Suspense>
          } 
        />
        <Route 
          path="/blog/:postName" 
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <BlogPostDetail data={landingPageData} />
            </Suspense>
          } 
        />
        {/* Development-only routes */}
        {process.env.NODE_ENV === 'development' && (
          <>
            <Route 
              path="/retail-listings" 
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <PropertyListings type="retail" />
                </Suspense>
              } 
            />
            <Route 
              path="/investment-properties" 
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <PropertyListings type="investment" />
                </Suspense>
              } 
            />
            <Route 
              path="/property/:id" 
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <PropertyDetails />
                </Suspense>
              }
            />
          </>
        )}
        <Route
          path="/"
          element={
            <>
              <Helmet>
                <link rel="canonical" href="https://www.vidcor.com/" />
              </Helmet>
              {showThankyou ? (
                <ThankYouSection data={landingPageData.ThankYouSection} />
              ) : showContact ? (
                <ContactFormSection
                  data={landingPageData.ContactFormSection}
                  currentService={currentService}
                  onFormSubmit={handleFormSubmit}
                />
              ) : (
                showHeader && (
                  <HeroSection
                    data={landingPageData.HeroSection}
                    onGetStartedClick={handleGetStartedClick}
                    currentService={currentService}
                  />
                )
              )}
              {overlayStatus !== 'none' && (
                <div className={`black-overlay black-overlay-${overlayStatus}`} />
              )}
              <Suspense fallback={<LoadingSpinner />}>
                {!showThankyou && (
                  <ServicesSection
                    data={landingPageData.ServicesSection}
                    onServiceButtonClick={handleServicesClick}
                  />
                )}
                <AboutSection data={landingPageData.AboutSection} />
                <HowItWorksSection data={landingPageData.HowItWorksSection} />
                <TestimonialsSection data={landingPageData.TestimonialsSection} />
              </Suspense>
            </>
          }
        />
      </Routes>
      <Footer
        onBuyClick={handleServicesClick}
        onSellClick={() => handleServicesClick('Sell Your Home Immediately')}
        onFooterClick={handleFooterClick}
      />
    </Suspense>
  );
};

const App = () => {
  return (
    <HelmetProvider>
      <Router basename="/">
        <AppContent />
      </Router>
    </HelmetProvider>
  );
};

export default App;