import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export const Header = ({ onBuyClick, onSellClick }) => {
  const navbarToggle = useRef(null);
  const dropdownRef = useRef(null);
  const navRef = useRef(null);
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const isDevelopment = process.env.NODE_ENV === 'development';

  // Handle clicks outside of both dropdown and navbar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if ((dropdownRef.current && !dropdownRef.current.contains(event.target)) ||
          (navRef.current && !navRef.current.contains(event.target) && !event.target.classList.contains('navbar-toggle'))) {
        setIsDropdownOpen(false);
        setIsNavCollapsed(true);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Handle navbar toggle
  const handleNavbarToggle = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setIsNavCollapsed(!isNavCollapsed);
    if (!isNavCollapsed) {
      setIsDropdownOpen(false);
    }
  };

  const handleNavigation = (event, callback) => {
    event.preventDefault();
    if (callback) callback();
    setIsNavCollapsed(true);
    setIsDropdownOpen(false);
    navigate("/");
    window.scrollTo(0, 0);
  };

  const toggleDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            ref={navbarToggle}
            type='button'
            className={`navbar-toggle collapsed ${!isNavCollapsed ? 'is-open' : ''}`}
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
            onClick={handleNavbarToggle}
          >
            <span className='sr-only'>Toggle navigation</span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
            <span className='icon-bar'></span>
          </button>
          <a
            className='navbar-brand page-scroll'
            href='#page-top'
            onClick={(e) => {
              e.preventDefault();
              window.location.href = '/';
              window.scrollTo(0, 0);
            }}
          >
            <img src='/img/logo-modern/vidcor-modern-brand-logo-white-wreath.png' alt='Logo' className='navbar-logo' />
            <span className='brand-text'>VIDCOR</span>
          </a>
        </div>

        <div ref={navRef} className={`collapse navbar-collapse ${!isNavCollapsed ? 'in' : ''}`} id='bs-example-navbar-collapse-1'>
          <ul className='nav navbar-nav navbar-right'>
            <li ref={dropdownRef} className={`dropdown ${isDropdownOpen ? 'open' : ''}`}>
              <a
                href="#"
                className="dropdown-toggle"
                onClick={toggleDropdown}
                aria-expanded={isDropdownOpen}
              >
                Buy <span className="custom-arrow"></span>
              </a>
              <ul className="dropdown-menu">
                {isDevelopment && (
                  <>
                    <li>
                      <Link 
                        to="/retail-listings" 
                        onClick={() => {
                          setIsDropdownOpen(false);
                          setIsNavCollapsed(true);
                        }}
                      >
                        Retail Listings
                      </Link>
                    </li>
                    <li>
                      <Link 
                        to="/investment-properties" 
                        onClick={() => {
                          setIsDropdownOpen(false);
                          setIsNavCollapsed(true);
                        }}
                      >
                        Investment Properties
                      </Link>
                    </li>
                  </>
                )}
                <li>
                  <a 
                    href="#" 
                    onClick={(e) => {
                      handleNavigation(e, onBuyClick);
                      setIsDropdownOpen(false);
                    }}
                  >
                    Get Started
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href='#about' className='page-scroll' onClick={(e) => handleNavigation(e, onSellClick)}>
                Sell
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll' onClick={(e) => handleNavigation(e)}>
                Services
              </a>
            </li>
            <li>
              <a href='#testimonials' className='page-scroll' onClick={(e) => handleNavigation(e)}>
                Reviews
              </a>
            </li>
            <li>
              <Link to="/blog" onClick={() => {
                setIsNavCollapsed(true);
                setIsDropdownOpen(false);
              }}>Blog</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};