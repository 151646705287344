import React, { useState, useEffect } from 'react';
import { onCLS, onFID, onLCP, onFCP, onTTFB } from 'web-vitals';

const PerformanceOverlay = () => {
  const [metrics, setMetrics] = useState({
    CLS: null,
    FID: null,
    LCP: null,
    FCP: null,
    TTFB: null,
    Device: window.innerWidth <= 768 ? 'Mobile' : 'Desktop',
    Connection: 'Unknown',
    LCPElement: null
  });

  const [isVisible, setIsVisible] = useState(true);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const getConnectionInfo = () => {
      if ('connection' in navigator) {
        const conn = navigator.connection;
        return `${conn.effectiveType || 'Unknown'} (${Math.round(conn.downlink)}Mbps)`;
      }
      return 'Unknown';
    };

    const handleResize = () => {
      setMetrics(prev => ({
        ...prev,
        Device: window.innerWidth <= 768 ? 'Mobile' : 'Desktop',
        Connection: getConnectionInfo()
      }));
    };

    const reportWebVital = (name) => (metric) => {
      if (name === 'LCP') {
        setMetrics(prev => ({
          ...prev,
          [name]: metric.value,
          LCPElement: metric.element?.outerHTML || 'Unknown'
        }));
      } else {
        setMetrics(prev => ({
          ...prev,
          [name]: metric.value
        }));
      }
    };

    onCLS(reportWebVital('CLS'));
    onFID(reportWebVital('FID'));
    onLCP(reportWebVital('LCP'));
    onFCP(reportWebVital('FCP'));
    onTTFB(reportWebVital('TTFB'));

    window.addEventListener('resize', handleResize);
    setMetrics(prev => ({ ...prev, Connection: getConnectionInfo() }));

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getMetricColor = (name, value) => {
    if (value === null) return '#666';
    
    const thresholds = {
      LCP: metrics.Device === 'Mobile' 
        ? { good: 2500, poor: 4000 }
        : { good: 2000, poor: 3000 },
      FID: { good: 100, poor: 300 },
      CLS: { good: 0.1, poor: 0.25 },
      FCP: metrics.Device === 'Mobile'
        ? { good: 1800, poor: 3000 }
        : { good: 1000, poor: 2000 },
      TTFB: { good: 600, poor: 1000 }
    };

    const threshold = thresholds[name];
    if (!threshold) return '#fff';

    return value <= threshold.good ? '#4ade80'
         : value <= threshold.poor ? '#fbbf24'
         : '#ef4444';
  };

  const formatMetricValue = (name, value) => {
    if (value === null) return 'Calculating...';
    if (name === 'CLS') return value.toFixed(3);
    return `${Math.round(value)}ms`;
  };

  const overlayStyle = {
    position: 'fixed',
    bottom: isVisible ? '20px' : '-400px',
    right: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    padding: '15px',
    borderRadius: '8px',
    fontFamily: 'monospace',
    fontSize: '12px',
    color: '#fff',
    transition: 'bottom 0.3s ease',
    zIndex: 10000,
    maxWidth: '400px',
    boxShadow: '0 0 10px rgba(0,0,0,0.5)'
  };

  const toggleButtonStyle = {
    position: 'fixed',
    bottom: isVisible ? '230px' : '20px',
    right: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    color: '#fff',
    border: 'none',
    padding: '8px 12px',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '12px',
    transition: 'bottom 0.3s ease',
    zIndex: 10000
  };

  const detailsButtonStyle = {
    backgroundColor: 'transparent',
    border: '1px solid #666',
    color: '#fff',
    padding: '4px 8px',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '11px',
    marginTop: '8px'
  };

  return (
    <>
      <button 
        style={toggleButtonStyle}
        onClick={() => setIsVisible(!isVisible)}
      >
        {isVisible ? 'Hide Metrics' : 'Show Metrics'}
      </button>
      <div style={overlayStyle}>
        <div style={{ borderBottom: '1px solid #666', marginBottom: '10px', paddingBottom: '10px' }}>
          <div>Device: {metrics.Device}</div>
          <div>Connection: {metrics.Connection}</div>
        </div>
        {Object.entries(metrics)
          .filter(([key]) => !['Device', 'Connection', 'LCPElement'].includes(key))
          .map(([key, value]) => (
            <div key={key} style={{ marginBottom: '8px', display: 'flex', justifyContent: 'space-between' }}>
              <span>{key}:</span>
              <span style={{ color: getMetricColor(key, value) }}>
                {formatMetricValue(key, value)}
              </span>
            </div>
          ))}
        <button 
          style={detailsButtonStyle}
          onClick={() => setShowDetails(!showDetails)}
        >
          {showDetails ? 'Hide LCP Details' : 'Show LCP Details'}
        </button>
        {showDetails && metrics.LCPElement && (
          <div style={{ marginTop: '10px', padding: '10px', backgroundColor: 'rgba(255,255,255,0.1)', borderRadius: '4px' }}>
            <div style={{ fontSize: '11px', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
              LCP Element:
              <br />
              {metrics.LCPElement}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PerformanceOverlay;